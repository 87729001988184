const paragraph =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.";
export const services = [
  { title: "Yard Grading", img: "img/services/yardGrading2.jpg", paragraph },
  {
    title: "Site Maintenance",
    img: "img/services/siteMaintenance2.jpg",
    paragraph:
      "We specialize in frame cleaning, drywall cleaning, masonry clean, and final clean.",
  },
  { title: "Brush Clearing", img: "img/services/brushClearing.jpg", paragraph },

  {
    title: "Concrete Driveways",
    img: "img/services/ConcreteDriveways.jpg",
    paragraph,
  },
  {
    title: "Concrete Foundations",
    img: "img/services/ConcreteFoundations.jpg",
    paragraph,
  },
  {
    title: "Custom Concrete",
    img: "img/services/CustomConcrete.jpg",
    paragraph,
  },
];
